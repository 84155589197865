import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
// import "@/assets/element-ui/index.css";
import "@/assets/element-ui/icon.css";
import "@/assets/element-ui/form.css";
import "@/assets/element-ui/input.css";
import "@/assets/element-ui/button.css";
import "@/assets/element-ui/container.css";
import "@/assets/element-ui/main.css";
import "@/assets/element-ui/header.css";
import "@/assets/element-ui/slider.css";
import "@/assets/element-ui/dropdown.css";
import "@/assets/element-ui/switch.css";
import "@/assets/element-ui/loading.css";
import "@/assets/element-ui/popconfirm.css";
import "@/assets/element-ui/popover.css";
import "@/assets/element-ui/popper.css";
import "@/assets/element-ui/upload.css";
import "@/assets/element-ui/dialog.css";
import "@/assets/element-ui/message.css";
import "@/assets/element-ui/select.css";
import "@/assets/element-ui/select-dropdown.css";
import "@/assets/element-ui/option-group.css";
import "@/assets/element-ui/option.css";
import "@/assets/element-ui/scrollbar.css";
import "@/assets/element-ui/radio.css";
import "@/assets/element-ui/table.css";
import "@/assets/element-ui/date-picker.css";
import "@/assets/element-ui/pagination.css";
import "@/assets/element-ui/notification.css";
import "@/assets/element-ui/autocomplete.css";
import "@/assets/element-ui/radio-group.css";
import "@/assets/element-ui/radio-button.css";

import "@/assets/scss/modalWindow.scss";
import "@/assets/scss/style.scss";
import "@/assets/fonts/proximaSoft/stylesheet.css";

import "@/assets/css/demo.css";

import lang from "element-ui/lib/locale/lang/ru-RU";
import locale from "element-ui/lib/locale";

locale.use(lang);
Vue.use(ElementUI);
Vue.config.productionTip = false;

import vueNumeralFilterInstaller from "vue-numeral-filter";
Vue.use(vueNumeralFilterInstaller, { locale: "ru" });

import VueMask from "v-mask";
Vue.use(VueMask, {
  placeholders: {
    "~": /[9]/,
  },
});

/** Vue Filters Start */
Vue.filter("truncate", function (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});
/** Vue Filters End */

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
