<template>
  <div id="app">
    <transition name="component-fade" mode="out-in" appear>
      <component :is="layout">
        <router-view />
      </component>
    </transition>
  </div>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";
export default {
  name: "App",
  components: {
    EmptyLayout,
    MainLayout,
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  },
};
</script>

<style lang="sass">
@import "src/assets/sass/screen"
</style>
