import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    meta: { layout: "main" },
    redirect: { name: "in_work" },
    beforeEnter: (to, from, next) => {
      if (!sessionStorage.getItem("AuthToken")) next({ name: "login" });
      else next();
    },
    component: () => import("../views/Main.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: { layout: "empty" },
    component: () => import("../views/Login.vue"),
  },
  // {
  //   path: "/news",
  //   name: "news",
  //   meta: { layout: "main" },
  //   beforeEnter: (to, from, next) => {
  //     if (!sessionStorage.getItem("AuthToken")) next({ name: "login" });
  //     else next();
  //   },
  //   component: () => import("../views/News.vue"),
  // },
  // {
  //   path: "/mini_srm",
  //   name: "mini_srm",
  //   meta: { layout: "main" },
  //   beforeEnter: (to, from, next) => {
  //     if (!sessionStorage.getItem("AuthToken")) next({ name: "login" });
  //     else next();
  //   },
  //   component: () => import("../views/MiniCRM.vue"),
  // },
  {
    path: "/new_order",
    name: "new_order",
    meta: { layout: "main" },
    redirect: { name: "list_products" },
    beforeEnter: (to, from, next) => {
      if (!sessionStorage.getItem("AuthToken")) next({ name: "login" });
      else next();
    },
    component: () => import("../views/NewOrder.vue"),
    children: [
      {
        path: "/new_order/list_products",
        name: "list_products",
        meta: {
          layout: "main",
          new_order_component: "ListProducts",
        },
        component: () => import("../views/NewOrder.vue"),
      },
      {
        path: "/new_order/cost_calculation/",
        name: "cost_calculation",
        meta: {
          layout: "main",
          new_order_component: "CostCalculation",
        },
        component: () => import("../views/NewOrder.vue"),
      },
    ],
  },
  {
    path: "/in_work",
    name: "in_work",
    meta: { layout: "main" },
    beforeEnter: (to, from, next) => {
      if (!sessionStorage.getItem("AuthToken")) next({ name: "login" });
      else next();
    },
    component: () => import("../views/InWork.vue"),
  },
  {
    path: "/in_work/:oId",
    name: "order_in_work",
    meta: { layout: "main" },
    beforeEnter: (to, from, next) => {
      if (!sessionStorage.getItem("AuthToken")) next({ name: "login" });
      else next();
    },
    // component: () => import("../views/InWorkOrder.vue"),
    component: () => import("../views/ListOrders.vue"),
  },
  {
    path: "/list_orders",
    name: "list_orders",
    meta: { layout: "main" },
    beforeEnter: (to, from, next) => {
      if (!sessionStorage.getItem("AuthToken")) next({ name: "login" });
      else next();
    },
    component: () => import("../views/TableListOrders.vue"),
  },
  {
    path: "/list_orders/:oId",
    name: "order",
    meta: { layout: "main" },
    beforeEnter: (to, from, next) => {
      if (!sessionStorage.getItem("AuthToken")) next({ name: "login" });
      else next();
    },
    component: () => import("../views/ListOrders.vue"),
  },
  {
    path: "/cc/add/:oId",
    name: "MainCcAdd",
    meta: { layout: "main" },
    beforeEnter: (to, from, next) => {
      if (!sessionStorage.getItem("AuthToken")) next({ name: "login" });
      else next();
    },
    component: () => import("../components/MainCc/MainCcAdd.vue"),
  },
  {
    path: "/cc/edit/:oId",
    name: "MainCcEdit",
    meta: { layout: "main" },
    beforeEnter: (to, from, next) => {
      if (!sessionStorage.getItem("AuthToken")) next({ name: "login" });
      else next();
    },
    component: () => import("../components/MainCc/MainCcEdit.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    redirect: { name: "my_settings" },
    meta: { layout: "main" },
    beforeEnter: (to, from, next) => {
      if (!sessionStorage.getItem("AuthToken")) next({ name: "login" });
      else next();
    },
    component: () => import("../views/Settings.vue"),
    children: [
      {
        path: "/settings/change_password",
        name: "change_password",
        meta: {
          layout: "main",
          setting_component: "ChangePassword",
          title: "Смена пароля",
        },
        // beforeEnter: (to, from, next) => {
        //   //todo костыль для демо
        //   store.dispatch("Main/checkToken").finally(() => {
        //     if (store.getters["Main/contactID"] === 25694) {
        //       next({ name: "my_settings" });
        //     } else next();
        //   });
        // },
        component: () => import("../views/Settings.vue"),
      },
      {
        path: "/settings/my_settings",
        name: "my_settings",
        meta: {
          layout: "main",
          setting_component: "MySettings",
          title: "Мои настройки",
        },
        component: () => import("../views/Settings.vue"),
      },
      {
        path: "/settings/users",
        name: "users",
        meta: {
          layout: "main",
          setting_component: "Users",
          title: "Пользователи",
        },
        beforeEnter: (to, from, next) => {
          if (store.getters["Main/IsAdmin"] === false)
            next({ name: "my_settings" });
          else next();
        },
        component: () => import("../views/Settings.vue"),
      },
      {
        path: "/settings/legal_entities",
        name: "legal_entities",
        meta: {
          layout: "main",
          setting_component: "LegalEntities",
          title: "Юридические лица",
        },
        beforeEnter: (to, from, next) => {
          if (store.getters["Main/IsAdmin"] === false)
            next({ name: "my_settings" });
          else next();
        },
        component: () => import("../views/Settings.vue"),
      },
      {
        path: "/settings/logo_on_label",
        name: "logo_on_label",
        meta: {
          layout: "main",
          setting_component: "LogoOnLabel",
          title: "Логотип на этикетке",
        },
        beforeEnter: (to, from, next) => {
          if (store.getters["Main/IsAdmin"] === false)
            next({ name: "my_settings" });
          else next();
        },
        component: () => import("../views/Settings.vue"),
      },
      // {
      //   path: "/settings/delivery_address",
      //   name: "delivery_address",
      //   meta: {
      //     layout: "main",
      //     setting_component: "DeliveryAddress",
      //     title: "Адреса доставки",
      //   },
      //   component: () => import("../views/Settings.vue"),
      // },
    ],
  },
  // {
  //   path: "/knowledge_base",
  //   name: "knowledge_base",
  //   meta: { layout: "main" },
  //   beforeEnter: (to, from, next) => {
  //     if (!sessionStorage.getItem("AuthToken")) next({ name: "login" });
  //     else next();
  //   },
  //   component: () => import("../views/KnowledgeBase.vue"),
  // },
  {
    path: "/404",
    name: "404",
    meta: { layout: "empty" },
    component: () => import("../views/Page-404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "page_not_found",
    meta: { layout: "empty" },
    component: () => import("../views/Page-404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
