/* Module1.store.js */
import { httpClient } from "@/services/http";

const initialState = () => ({
  responseLogin: {},
  customerLogout: {},
});

const state = initialState();

// Getter functions
const getters = {};

// Actions
const actions = {
  LOGIN({ commit }, request) {
    return httpClient("/?action=customerLogin", {
      data: {
        request: request,
      },
    })
      .then((response) => {
        commit("SET_LOGIN", response.data);
        if (response.data.status === 2000) {
          sessionStorage.setItem("AuthToken", response.data.response.token);
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerLogout({ commit }) {
    return httpClient("/?action=customerLogout", {
      data: {},
    })
      .then((response) => {
        commit("SET_LOGIN", response.data);
        if (response.data.status === 2000) {
          sessionStorage.setItem("AuthToken", response.data.response.token);
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
};
// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  SET_LOGIN(state, value) {
    state.responseLogin = value;
  },
  customerLogout(state, value) {
    state.customerLogout = value;
  },
  // other mutations
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
