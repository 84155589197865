/* CostCalculation.store.js */
import { httpCost } from "@/services/httpCostCalculation";
import Vue from "vue";

const initialState = () => ({
  contactID: 0,
  preloader: true,
  preloader_display_none: false,
  template: [],
  types: [],
  characteristics: [],
  calculate: {
    template: {
      id_template: 19,
      id_type: 22,
      characteristics: [],
    },
  },
  type: [{}],
  cost: [],
  response_phone_auth: {},
  response_sms_auth: {},
  costPreloader: false,
  number_of_requests: 0,
  exceptions: [],
  validation_quantity: {},
  oid: "0",
});

const state = initialState();

// Getter functions
const getters = {
  TEMPLATE(state) {
    return state.template;
  },
  LIST_TYPES(state) {
    return state.types;
  },
  ONE_TYPE(state) {
    return state.type;
  },
  TYPE(state) {
    return state.characteristics;
  },
  TYPE_ID(state) {
    return state.type[0].id;
  },
  CHARACTERISTICS(state) {
    return state.calculate.template.characteristics;
  },
  COST(state) {
    return state.cost;
  },
  COST_PRELOADER(state) {
    return state.costPreloader;
  },
  PRELOADER(state) {
    return state.preloader;
  },
  PRELOADER_DISPLAY_NONE(state) {
    return state.preloader_display_none;
  },
  USER_ID(state) {
    return state.user_id; //todo добавить
  },
  NUMBER_OF_REQUESTS(state) {
    return state.number_of_requests;
  },
  EXEPTIONS(state) {
    return state.exceptions;
  },
  VALIDATION_QUANTITY(state) {
    return state.validation_quantity;
  },
  list_of_selected_options(state) {
    let array = [];
    state.calculate.template.characteristics.forEach((item) => {
      array.push(item.id_value);
    });
    return array;
  },
  contactID(state) {
    return state.contactID;
  },
};

// Actions
const actions = {
  GET_TEMPLATE_FROM_API({ commit }) {
    commit("SET_PRELOADER_TO_STATE", true);
    commit("SET_PRELOADER_DISPLAY_NONE_TO_STATE", false);
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    // commit("SET_OID_TO_STATE", params.get("oid"));
    return httpCost(
      "/goodprint/api/?action=template&tid=" + params.get("templateId"),
      {
        method: "GET",
        data: {},
      }
    )
      .then((response) => {
        commit("SET_TEMPLATE_TO_STATE", response.data);
        commit("SET_TYPES_TO_STATE", response.data);
        commit("SET_CALCULATE_TEMPLATE_ID", response.data);
        commit("SET_PRELOADER_TO_STATE", false);
        commit("SET_PRELOADER_DISPLAY_NONE_TO_STATE", true);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  GET_CHARACTERISTICS({ commit }, item) {
    commit("SET_CHARACTERISTICS", item);
  },
  GET_CALCULATE_TEMPLATE_CHARACTERISTICS({ commit }, characteristics) {
    commit("SET_CALCULATE_TEMPLATE_CHARACTERISTICS", characteristics);
  },
  GET_CALCULATE({ commit }, add) {
    if (add === true) {
      // commit("SET_PRELOADER_TO_STATE", true);
      commit("SET_COST_PRELOADER", false);
    }
    commit("SET_COST_PRELOADER", true);
    commit("increment");

    return httpCost(
      "/goodprint/api/?action=calculate" +
        (add ? "&isprodupdate=1" : "") +
        (add && state.contactID !== 0 ? "&contactid=" + state.contactID : ""),
      {
        data: state.calculate,
      }
    )
      .then((response) => {
        if (add === true) {
          commit("SET_PRELOADER_TO_STATE", false);
        }
        commit("SET_CALCULATE_TEMPLATE", response.data);
        commit("SET_COST_PRELOADER", false);
        commit("decrement");
        return response;
      })
      .catch((error) => {
        commit("decrement");
        return error;
      });
  },
  SET_ID_TYPE({ commit }, id_type) {
    commit("SET_CALCULATE_TEMPLATE_ID_TYPE", id_type);
  },
  SET__TYPE({ commit }, type) {
    commit("SET_TYPE", type);
  },
  SET__EXEPTIONS({ commit }, exept) {
    commit("SET_EXEPTIONS", exept);
  },
  SET_VALIDATION_QUANTITY({ commit }, value) {
    commit("SET_VALIDATION_QUANTITY", value);
  },
};
// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  SET_COST_PRELOADER: (state, value) => {
    state.costPreloader = value;
  },
  increment(state) {
    state.number_of_requests++;
  },
  decrement(state) {
    state.number_of_requests--;
  },
  SET_USER_ID: (state, value) => {
    state.userId = value;
  },
  SET_RESPONSE_PHONE_AUTH: (state, value) => {
    state.response_phone_auth = value;
  },
  SET_RESPONSE_SMS_AUTH: (state, value) => {
    state.response_sms_auth = value;
  },
  SET_PRELOADER_TO_STATE: (state, value) => {
    state.preloader = value;
  },
  SET_PRELOADER_DISPLAY_NONE_TO_STATE: (state, value) => {
    state.preloader_display_none = value;
  },
  SET_OID_TO_STATE: (state, value) => {
    state.oid = value;
  },
  SET_TEMPLATE_TO_STATE: (state, template) => {
    state.template = template;
  },
  SET_TYPES_TO_STATE: (state, template) => {
    state.types = template.types_of_characteristics;
  },
  SET_CHARACTERISTICS: (state, characteristics) => {
    state.characteristics = characteristics;
  },
  SET_CALCULATE_TEMPLATE: (state, response) => {
    // Vue.set(state.cost, 0, response);
    state.cost = response;
  },
  SET_CALCULATE_TEMPLATE_ID: (state, template) => {
    state.calculate.template.id_template = parseInt(template.id);
  },
  SET_CALCULATE_TEMPLATE_ID_TYPE: (state, id_type) => {
    state.calculate.template.id_type = parseInt(id_type);
  },
  SET_TYPE: (state, type) => {
    Vue.set(state.type, 0, type);
    // state.type = type;
  },
  SET_EXEPTIONS: (state, exept) => {
    // Vue.set(state.type, 0, exept);
    state.exceptions = exept;
    // state.exceptions = exept;
  },
  SET_VALIDATION_QUANTITY: (state, value) => {
    Vue.set(state.validation_quantity, value.key, value.value);
  },
  SET_CALCULATE_TEMPLATE_CHARACTERISTICS: (state, characteristics) => {
    Vue.set(state.calculate.template, "characteristics", characteristics);
    // state.calculate.template.characteristics = characteristics;
  },
  SET_VUE_CHARACTERISTICS: (state, { characteristics, index }) => {
    Vue.set(state.calculate.template.characteristics, index, characteristics);
  },
  SET_VUE_CHARACTERISTICS_OBJ: (state, characteristics) => {
    state.calculate.template.characteristics = characteristics;
  },
  contactID(state, value) {
    state.contactID = value;
  },
  // other mutations
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
