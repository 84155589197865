/* Settings.store.js */
import { httpClient } from "@/services/http";

const initialState = () => ({
  customerTemplates: {},
  Templates: {},
  customerUserList: {},
  UserList: {},
  customerCompanyList: {},
  CompanyList: {},
  customerUserUpdate: {},
  customerUserOnline: {},
  customerUserPassword: {},
  customerUserDelete: {},
  getUserInfo: {},
  UserInfo: {},
  setUserInfo: {},
  customerFileGetLogo: {},
  customerFileDeleteLogo: {},
  customerLogo: "",
});

const state = initialState();

// Getter functions
const getters = {
  customerUserList(state) {
    return state.customerUserList;
  },
  UserList(state) {
    return state.UserList;
  },
  CompanyList(state) {
    return state.CompanyList;
  },
  Templates(state) {
    return state.Templates;
  },
  UserInfo(state) {
    return state.UserInfo;
  },
  customerLogo(state) {
    return state.customerLogo;
  },
};

// Actions
const actions = {
  customerUserList({ commit }) {
    return httpClient("/?action=customerUserList", {
      data: {},
    })
      .then((response) => {
        commit("customerUserList", response.data);
        if (response.data.status === 2000 && response.data.response.userlist) {
          commit("UserList", response.data.response.userlist);
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerUserUpdate({ commit, dispatch }, request) {
    return httpClient("/?action=customerUserUpdate", {
      data: {
        request: request,
        // request: {
        //   id: 0, // 0 - добавление, если id контблица то обновление
        //   name: "Петя",
        //   surname: "Петров",
        //   phone: "79999999999",
        //   email: "test@test.ru",
        //   login: "test01",
        //   password: "password",
        //   infosms: true,
        //   infoemail: true,
        // },
      },
    })
      .then((response) => {
        commit("customerUserUpdate", response.data);
        if (response.data.status === 2000) {
          dispatch("customerUserList");
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerUserOnline({ commit, dispatch }, { user_id, online }) {
    return httpClient("/?action=customerUserOnline", {
      data: {
        request: {
          id: user_id, // id контактного лица
          online: online, // bool
        },
      },
    })
      .then((response) => {
        commit("customerUserOnline", response.data);
        dispatch("customerUserList");
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerUserPassword({ commit }, request) {
    return httpClient("/?action=customerUserPassword", {
      data: {
        request: request,
      },
    })
      .then((response) => {
        commit("customerUserPassword", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerUserDelete({ commit, dispatch }, id) {
    return httpClient("/?action=customerUserDelete", {
      data: {
        request: {
          id: id, // id контактного лица
        },
      },
    })
      .then((response) => {
        commit("customerUserDelete", response.data);
        if (response.data.status === 2000) {
          dispatch("customerUserList");
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerCompanyList({ commit }) {
    return httpClient("/?action=customerCompanyList", {
      data: {},
    })
      .then((response) => {
        commit("customerCompanyList", response.data);
        if (response.data.status === 2000 && response.data.response.companies) {
          commit("CompanyList", response.data.response.companies);
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  getUserInfo({ commit }) {
    return httpClient("/?action=getUserInfo", {
      data: {},
    })
      .then((response) => {
        commit("getUserInfo", response.data);
        if (response.data.status === 2000 && response.data.response) {
          commit("UserInfo", response.data.response);
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  setUserInfo({ commit }, request) {
    return httpClient("/?action=setUserInfo", {
      data: {
        request: request,
      },
    })
      .then((response) => {
        commit("setUserInfo", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerFileGetLogo({ commit }) {
    return httpClient("/?action=customerFileGetLogo", {
      data: {},
    })
      .then((response) => {
        commit("customerFileGetLogo", response.data);
        if (
          response.data.status === 2000 &&
          response.data.response.customerLogo
        ) {
          commit("customerLogo", response.data.response.customerLogo);
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerFileDeleteLogo({ commit }) {
    return httpClient("/?action=customerFileDeleteLogo", {
      data: {},
    })
      .then((response) => {
        commit("customerFileDeleteLogo", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
};
// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  customerTemplates(state, value) {
    state.customerTemplates = value;
  },
  customerFileGetLogo(state, value) {
    state.customerFileGetLogo = value;
  },
  customerFileDeleteLogo(state, value) {
    state.customerFileDeleteLogo = value;
  },
  customerLogo(state, value) {
    state.customerLogo = value;
  },
  Templates(state, value) {
    state.Templates = value;
  },
  customerUserList(state, value) {
    state.customerUserList = value;
  },
  UserList(state, value) {
    state.UserList = value;
  },
  customerCompanyList(state, value) {
    state.customerCompanyList = value;
  },
  CompanyList(state, value) {
    state.CompanyList = value;
  },
  customerUserUpdate(state, value) {
    state.customerUserUpdate = value;
  },
  customerUserOnline(state, value) {
    state.customerUserOnline = value;
  },
  customerUserPassword(state, value) {
    state.customerUserPassword = value;
  },
  customerUserDelete(state, value) {
    state.customerUserPassword = value;
  },
  getUserInfo(state, value) {
    state.getUserInfo = value;
  },
  UserInfo(state, value) {
    state.UserInfo = value;
  },
  setUserInfo(state, value) {
    state.setUserInfo = value;
  },
  // other mutations
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
