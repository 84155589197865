import axios from "axios";

// import * as AxiosLogger from "axios-logger";

/** Базовая конфигурация axios */
let config = {
  // Базовый URL-адрес сервера
  baseURL: "https://b2b.gprint.ru",
  // baseURL: "https://devlin.ru",
  method: "POST",
  contentType: "application/json; charset=utf-8",
  dataType: "json",
  crossDomain: true,
  crossOrigin: true,
};

/** Создание экземпляра axios */
const httpCost = axios.create(config);

/** Добавление токена аутентификации, и обновление его, если это требуется и добавление пустого объекта request по умолчанию */
const authInterceptor = (config) => {
  // достаём токент аутентификации пользователя, с LocalStorage, или cookies, например
  let authToken = "";
  if (sessionStorage.getItem("AuthToken")) {
    authToken = sessionStorage.getItem("AuthToken");
  }
  config.data.token = authToken;
  if (!config.data.request) {
    config.data.request = {};
  }
  return config;
};

/*Добавляем apiKey к запросу*/
const apiKeyInterceptor = (config) => {
  const apiKey = "30915f02-b0e8-4066-8540-1244d47d7304";
  config.headers.Authorization = `Bearer ${apiKey}`;

  return config;
};

/** добавлени логгера при каждом axios запросе */
const loggerInterceptor = (config) => {
  /** как-то обрабатываем логи */
  return config;
};

/** Добавляем экземпляру созданные обработчики для аутентификации и логов */
httpCost.interceptors.request.use(apiKeyInterceptor);
httpCost.interceptors.request.use(authInterceptor);
httpCost.interceptors.request.use(loggerInterceptor);
/*logger*/
// httpCost.interceptors.request.use(AxiosLogger.requestLogger);
// httpCost.interceptors.response.use(AxiosLogger.responseLogger);
// httpCost.interceptors.request.use(
//   AxiosLogger.requestLogger,
//   AxiosLogger.errorLogger
// );
// httpCost.interceptors.response.use(
//   AxiosLogger.responseLogger,
//   AxiosLogger.errorLogger
// );

/** Добавление обработчика для результатов или ошибок при запросах */
httpCost.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    /** Как-то обрабатываем результат, завершенный ошибкой */
    return Promise.reject(error);
  }
);

export { httpCost };
