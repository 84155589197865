/* Main.store.js */

import { httpClient } from "@/services/http";

const initialState = () => ({
  customerName: "",
  customerID: 0,
  contactID: 0,
  credit: {},
  checkToken: {},
  customerOrderNewUpdate: {},
  IsAdmin: false,
});

const state = initialState();

// Getter functions
const getters = {
  customerName(state) {
    return state.customerName;
  },
  customerID(state) {
    return state.customerID;
  },
  credit(state) {
    return state.credit;
  },
  IsAdmin(state) {
    return state.IsAdmin;
  },
  contactID(state) {
    return state.contactID;
  },
};

// Actions
const actions = {
  checkToken({ commit }) {
    return httpClient("/?action=checkToken", {
      data: {},
    })
      .then((response) => {
        commit("checkToken", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerOrderNewUpdate({ commit }, oid) {
    return httpClient("/?action=customerOrderNewUpdate", {
      data: {
        request: { oid: oid },
      },
    })
      .then((response) => {
        if (response.data.status && response.data.status === 2000) {
          commit("customerOrderNewUpdate", response);
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
};
// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  customerName(state, value) {
    state.customerName = value;
  },
  customerID(state, value) {
    state.customerID = value;
  },
  credit(state, value) {
    state.credit = value;
  },
  checkToken(state, value) {
    state.checkToken = value;
  },
  customerOrderNewUpdate(state, value) {
    state.customerOrderNewUpdate = value;
  },
  IsAdmin(state, value) {
    state.IsAdmin = value;
  },
  contactID(state, value) {
    state.contactID = value;
  },
  // other mutations
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
