/* NewOrder.store.js */
import { httpClient } from "@/services/http";

const initialState = () => ({
  customerTemplates: {},
  Templates: {},
  newOrderOid: 0,
});

const state = initialState();

// Getter functions
const getters = {
  Templates(state) {
    return state.Templates;
  },
  newOrderOid(state) {
    return state.newOrderOid;
  },
};

// Actions
const actions = {
  customerTemplates({ commit }) {
    return httpClient("/?action=customerOrderNew", {
      data: {},
    })
      .then((response) => {
        commit("customerTemplates", response.data);
        if (response.data.status && response.data.status === 2000) {
          if (response.data.response.templates) {
            commit("Templates", response.data.response.templates);
          }
          if (response.data.response.oid) {
            commit("newOrderOid", response.data.response.oid);
          }
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
};
// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  customerTemplates(state, value) {
    state.customerTemplates = value;
  },
  Templates(state, value) {
    state.Templates = value;
  },
  newOrderOid(state, value) {
    state.newOrderOid = value;
  },
  // other mutations
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
