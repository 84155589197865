<template>
  <nav class="header_menu">
    <router-link
      v-for="link in links"
      :key="link.url"
      :to="link.url"
      active-class="active_link"
      class="header_menu_link screen_min_1340"
      >{{ link.title }}</router-link
    >
    <el-dropdown size="medium" trigger="click" class="screen_max_1340">
      <el-button size="medium" type="primary" plain style="width: 110px">
        Меню<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="link in links" :key="link.url">
          <router-link
            :to="link.url"
            active-class="active_link"
            class="header_menu_drop_link"
            >{{ link.title }}</router-link
          >
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </nav>
</template>
<script>
export default {
  name: "TheHeaderMenu",
  data() {
    return {
      links: [
        // { title: "miniCRM", url: "/mini_srm" },
        { title: "Новый заказ", url: "/new_order" },
        { title: "В работе", url: "/in_work" },
        { title: "Список заказов", url: "/list_orders" },
        // { title: "Новости", url: "/news" },
        // { title: "База знаний", url: "/knowledge_base" },
        { title: "Настройки", url: "/settings" },
        // { title: "Шаблоны", url: "/templates" },
      ],
    };
  },
};
</script>
<style lang="sass">
@import "src/assets/sass/main_layout"
</style>
