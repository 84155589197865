import Vue from "vue";
import Vuex from "vuex";
import { createLogger } from "vuex";

import modules from "./modules";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules,
  actions: {
    reset({ commit }) {
      // resets state of all the modules
      Object.keys(modules).forEach((moduleName) => {
        commit(`${moduleName}/RESET`);
      });
    },
  },
  strict: debug,
  plugins: debug
    ? [
        createLogger({
          collapsed: false, // автоматически раскрывать залогированные мутации
          logActions: false, // логирование действий
          logMutations: false, // логирование мутаций
          logger: console, // реализация API `console`, по умолчанию `console`
        }),
      ]
    : [], // set logger only for development
});
