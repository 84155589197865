/* Module1.store.js */
import { httpClient } from "@/services/http";

const initialState = () => ({
  customerTemplates: {},
  customerUserList: {},
  customerCompanyList: {},
  customerUserUpdate: {},
  customerUserOnline: {},
  customerUserPassword: {},
  customerUserDelete: {},
});

const state = initialState();

// Getter functions
const getters = {};

// Actions
const actions = {
  customerTemplates({ commit }) {
    return httpClient("/?action=customerTemplates", {
      data: {},
    })
      .then((response) => {
        commit("customerTemplates", response.data);
      })
      .catch((error) => {
        return error;
      });
  },
  customerUserList({ commit }) {
    return httpClient("/?action=customerUserList", {
      data: {},
    })
      .then((response) => {
        commit("customerUserList", response.data);
      })
      .catch((error) => {
        return error;
      });
  },
  customerUserUpdate({ commit }) {
    return httpClient("/?action=customerUserUpdate", {
      data: {
        request: {
          id: 14771, // 0 - добавление, если id контблица то обновление
          name: "Петя",
          surname: "Петров",
          phone: "79999999999",
          email: "test@test.ru",
          login: "test01",
          password: "password",
          infosms: true,
          infoemail: true,
        },
      },
    })
      .then((response) => {
        commit("customerUserUpdate", response.data);
      })
      .catch((error) => {
        return error;
      });
  },
  customerUserOnline({ commit }) {
    return httpClient("/?action=customerUserOnline", {
      data: {
        request: {
          id: 14771, // id контактного лица
          online: false, // bool
        },
      },
    })
      .then((response) => {
        commit("customerUserOnline", response.data);
      })
      .catch((error) => {
        return error;
      });
  },
  customerUserPassword({ commit }) {
    return httpClient("/?action=customerUserPassword", {
      data: {
        request: {
          password: "123456",
          newpassword: "79095228080",
          newpasswordconfirm: "79095228080",
        },
      },
    })
      .then((response) => {
        commit("customerUserPassword", response.data);
      })
      .catch((error) => {
        return error;
      });
  },
  customerUserDelete({ commit }) {
    return httpClient("/?action=customerUserDelete", {
      data: {
        request: {
          id: 14771, // id контактного лица
        },
      },
    })
      .then((response) => {
        commit("customerUserDelete", response.data);
      })
      .catch((error) => {
        return error;
      });
  },
  customerCompanyList({ commit }) {
    return httpClient("/?action=customerCompanyList", {
      data: {},
    })
      .then((response) => {
        commit("customerCompanyList", response.data);
      })
      .catch((error) => {
        return error;
      });
  },
};
// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  customerTemplates(state, value) {
    state.customerTemplates = value;
  },
  customerUserList(state, value) {
    state.customerUserList = value;
  },
  customerCompanyList(state, value) {
    state.customerCompanyList = value;
  },
  customerUserUpdate(state, value) {
    state.customerUserUpdate = value;
  },
  customerUserOnline(state, value) {
    state.customerUserOnline = value;
  },
  customerUserPassword(state, value) {
    state.customerUserPassword = value;
  },
  customerUserDelete(state, value) {
    state.customerUserPassword = value;
  },
  // other mutations
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
