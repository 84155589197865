/* Dadata.store.js */

import axios from "axios";

const initialState = () => ({
  getAddress: {},
  street_fias_id: "",
  qc_geo: undefined,
  fias_level: undefined,
  addressDelivery: undefined,
});

const state = initialState();

// Getter functions
const getters = {
  street_fias_id(state) {
    return state.street_fias_id;
  },
  qc_geo(state) {
    return state.qc_geo;
  },
  fias_level(state) {
    return state.fias_level;
  },
  addressDelivery(state) {
    return state.addressDelivery;
  },
};

// Actions
const actions = {
  getAddress({ commit }, request) {
    return axios(
      "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
      {
        crossOrigin: true,
        method: "POST",
        crossDomain: true,
        contentType: "application/json; charset=utf-8",
        headers: {
          Accept: "application/json",
          Authorization: "Token 994e72e919cff7cab5d3ef7a4eed11fa9b44cfc0",
        },
        dataType: "json",
        data: request,
      }
    )
      .then((response) => {
        commit("getAddress", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
};
// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  getAddress(state, value) {
    state.getAddress = value;
  },
  street_fias_id(state, value) {
    state.street_fias_id = value;
  },
  qc_geo(state, value) {
    state.qc_geo = value;
  },
  fias_level(state, value) {
    state.fias_level = value;
  },
  addressDelivery(state, value) {
    state.addressDelivery = value;
  },
  // other mutations
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
