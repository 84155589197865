/* Module1.store.js */

const initialState = () => ({
  Methods: [
    {
      type: "pickup",
      name: "Самовывоз",
      status: true,
      points: {
        type: "FeatureCollection",
        metadata: {
          name: "Pickup Points GoodPrint",
          creator: "Yandex Map Constructor",
        },
        features: [
          {
            type: "Feature",
            id: 1,
            geometry: { type: "Point", coordinates: [60.737468, 56.8683] },
            properties: {
              description: "Производство, г.Екатеринбург, ул. Изоплитная, 23 Б",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 3,
            geometry: { type: "Point", coordinates: [60.579939, 56.842152] },
            properties: {
              description: 'Офис "Северный", г.Екатеринбург, пер. Северный, 2А',
              "marker-color": "#e6761b",
            },
          },
        ],
      },
      summ: 0,
      date: "2021-08-20 19:00:00",
    },
    {
      type: "pickpoint",
      name: "Постоматы PickPoint",
      status: true,
      points: {
        type: "FeatureCollection",
        metadata: {
          name: "PickPoints GoodPrint",
          creator: "Yandex Map Constructor",
        },
        features: [
          {
            type: "Feature",
            id: 1,
            geometry: { type: "Point", coordinates: [60.631554, 56.863305] },
            properties: {
              description:
                "Постамат: PickPoint: ТЦ Парк Хаус, Екатеринбург, Сулимова ул.,  д. 50",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 2,
            geometry: { type: "Point", coordinates: [60.631009, 56.882255] },
            properties: {
              description:
                "Постамат: PickPoint: ТЦ Окей, Екатеринбург, Бабушкина ул., д. 2 А",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 3,
            geometry: { type: "Point", coordinates: [60.53908, 56.81702] },
            properties: {
              description:
                "Постамат: PickPoint: ТРЦ Радуга Парк, Екатеринбург, Репина ул.,  д. 94",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 4,
            geometry: { type: "Point", coordinates: [60.580936, 56.79585] },
            properties: {
              description:
                "Постамат: PickPoint: ТРЦ Кит, Екатеринбург, Амундсена ул., 65",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 5,
            geometry: { type: "Point", coordinates: [60.586241, 56.816366] },
            properties: {
              description:
                "Постамат: PickPoint: ТРЦ Фан Фан, Екатеринбург, Ясная ул., д. 2",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 7,
            geometry: { type: "Point", coordinates: [60.648966, 56.788968] },
            properties: {
              description:
                "Постамат: PickPoint: ТРК Глобус, Екатеринбург, Щербакова ул., 4",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 8,
            geometry: { type: "Point", coordinates: [60.547861, 56.779338] },
            properties: {
              description:
                "Постамат: PickPoint: ТЦ Краснолесье, Екатеринбург, Краснолесья ул., д. 12 А",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 14,
            geometry: { type: "Point", coordinates: [60.560453, 56.83922] },
            properties: {
              description:
                "Постамат: PickPoint: Магнит, Екатеринбург, Кирова ул., д. 33",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 16,
            geometry: { type: "Point", coordinates: [60.617088, 56.832424] },
            properties: {
              description:
                "Постамат: PickPoint: Елисейский: Красноармейская, Екатеринбург, Красноармейская ул., д. 64",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 17,
            geometry: { type: "Point", coordinates: [60.611272, 56.803043] },
            properties: {
              description:
                "Постамат: PickPoint: Елисейский: 8 Марта, Екатеринбург, 8 Марта ул., д. 190",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 18,
            geometry: { type: "Point", coordinates: [60.617097, 56.844495] },
            properties: {
              description:
                "Постамат: PickPoint: БЦ Манхэттен, Екатеринбург, Мамина-Сибиряка ул., д. 101",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 19,
            geometry: { type: "Point", coordinates: [60.516437, 56.811539] },
            properties: {
              description:
                "Постамат: PickPoint: ТЦ Светлореченский: Кировский, Екатеринбург, Соболева ул.,  д. 5",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 20,
            geometry: { type: "Point", coordinates: [60.562575, 56.816833] },
            properties: {
              description:
                "Постамат: PickPoint: ТЦ GoodMart, Екатеринбург, Белореченская ул., д. 28",
              "marker-color": "#e6761b",
            },
          },
        ],
      },
      summ: 0,
      date: "2021-08-20 19:00:00",
    },
    {
      type: "delivery",
      name: "Доставка курьером",
      status: true,
      points: null,
      summ: 199,
      date: "2021-08-21T10:00:00",
      citys: [
        {
          name: "г. Екатеринбург",
          locations: [{ city_fias_id: "2763c110-cb8b-416a-9dac-ad28a55b4402" }],
        },
        {
          name: "г. Березовский",
          locations: [{ city_fias_id: "adf5df2b-2c2e-45a9-b971-05550353cf43" }],
        },
        {
          name: "г. Верхняя Пышма",
          locations: [{ city_fias_id: "061cd8e0-b7bd-4a31-944e-a6e3da93631d" }],
        },
        {
          name: "г. Ревда",
          locations: [{ city_fias_id: "b1a4b393-ac99-4447-a11d-c3b454fa3d8e" }],
        },
        {
          name: "п. Большой Исток",
          locations: [
            { settlement_fias_id: "a1d04c1e-330d-4d5a-9f0a-f20972a5d1b8" },
          ],
        },
        {
          name: "п. Полевой",
          locations: [
            { settlement_fias_id: "d97b8d17-767b-42c4-a2dd-19d9c9515027" },
          ],
        },
        {
          name: "п. Садовый",
          locations: [
            { settlement_fias_id: "b5f9cb00-c1e3-4e89-91f0-cf43a1ac347d" },
          ],
        },
        {
          name: "г. Арамиль",
          locations: [{ city_fias_id: "c99ffa11-6b7e-4040-9f9e-baa1eeca8a91" }],
        },
        {
          name: "г. Среднеуральск",
          locations: [{ city_fias_id: "7c470adb-7d3d-42a1-9190-8f3fd05f710d" }],
        },
        {
          name: "г. Нижний Тагил",
          locations: [{ city_fias_id: "cc73d6af-6e2e-4a1f-be8e-682c289b0b57" }],
        },
      ],
    },
    {
      type: "myAddresses",
      name: "Мои адреса",
      status: true,
      points: {
        type: "FeatureCollection",
        metadata: {
          name: "Pickup Points GoodPrint",
          creator: "Yandex Map Constructor",
        },
        features: [
          {
            type: "Feature",
            id: 1,
            geometry: { type: "Point", coordinates: [60.737468, 56.8683] },
            properties: {
              description: "Дом, г.Екатеринбург, ул. Ленина, 23 Б",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 3,
            geometry: { type: "Point", coordinates: [60.579939, 56.842152] },
            properties: {
              description:
                'Офис "Московская", г.Екатеринбург, ул. Московская, 2А',
              "marker-color": "#e6761b",
            },
          },
        ],
      },
      summ: 0,
      date: "2021-08-20 19:00:00",
    },
    {
      type: "sdek",
      name: "Доставка СДЭК",
      status: false,
      points: {
        type: "FeatureCollection",
        metadata: {
          name: "Pickup Points GoodPrint",
          creator: "Yandex Map Constructor",
        },
        features: [
          {
            type: "Feature",
            id: 1,
            geometry: { type: "Point", coordinates: [60.737468, 56.8683] },
            properties: {
              description: "Производство, г.Екатеринбург, ул. Изоплитная, 23 Б",
              "marker-color": "#e6761b",
            },
          },
          {
            type: "Feature",
            id: 3,
            geometry: { type: "Point", coordinates: [60.579939, 56.842152] },
            properties: {
              description: 'Офис "Северный", г.Екатеринбург, пер. Северный, 2А',
              "marker-color": "#e6761b",
            },
          },
        ],
      },
      summ: 0,
      date: "2021-08-20 19:00:00",
    },
  ],
});

const state = initialState();

// Getter functions
const getters = {
  getMethods(state) {
    return state.Methods;
  },
};

// Actions
const actions = {
  reset({ commit }) {
    commit("RESET");
  },
};
// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  // other mutations
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
