<template>
  <el-container>
    <el-header height="72px">
      <div class="header">
        <router-link class="header_logo" to="/"></router-link>
        <header-menu />
        <div class="header_slider screen_max_720">
          <el-slider
            v-model="usedCredit"
            :max="creditLimit"
            :format-tooltip="formatTooltip"
            disabled
          ></el-slider>
        </div>
        <div class="header_btn screen_max_720">
          <el-button
            size="medium"
            style="width: 110px"
            @click.prevent="goToLogin"
            :loading="false"
            plain
            >Выход</el-button
          >
        </div>
      </div>
    </el-header>
    <el-main><router-view /></el-main>
  </el-container>
</template>

<script>
import HeaderMenu from "@/components/TheHeaderMenu";

import numeral from "numeral";

export default {
  name: "MainLayout",
  components: { HeaderMenu },
  data() {
    return {
      usedCredit: 0,
      creditLimit: 0,
    };
  },
  computed: {
    credit() {
      return this.$store.getters["Main/credit"];
    },
  },
  watch: {
    credit(value) {
      if (value.balance && value.balance !== "") {
        this.usedCredit = value.balance;
      } else {
        this.usedCredit = 0;
      }
      if (value.limit && value.limit !== "") {
        this.creditLimit = value.limit;
      } else {
        this.creditLimit = 0;
      }
    },
  },
  methods: {
    goToLogin() {
      this.$store.dispatch("Login/customerLogout").then((response) => {
        if (response.data.status && response.data.status === 2000) {
          sessionStorage.clear();
        }
      });
      this.$router.push({ path: "/login" });
    },
    formatTooltip(val) {
      return (
        "Кредитный лимит использовано: " +
        numeral(val).format("0,0") +
        " ₽" +
        " из " +
        numeral(this.creditLimit).format("0,0") +
        " ₽"
      );
    },
  },
};
</script>
<style lang="sass">
@import "src/assets/sass/main_layout"
</style>
