import axios from "axios";
import router from "@/router";
import { Notification } from "element-ui";
import store from "@/store/index.js";

// import * as AxiosLogger from "axios-logger";

/** Базовая конфигурация axios */
let config = {
  // Базовый URL-адрес сервера
  // baseURL: "https://api.devlin.ru",
  baseURL: "https://api.gprint.ru",
  method: "POST",
  contentType: "application/json; charset=utf-8",
  dataType: "json",
  crossDomain: true,
  crossOrigin: true,
};

/** Создание экземпляра axios */
const httpClient = axios.create(config);

/** Добавление токена аутентификации, и обновление его, если это требуется и добавление пустого объекта request по умолчанию */
const authInterceptor = (config) => {
  // достаём токент аутентификации пользователя, с LocalStorage, или cookies, например
  let authToken = "";
  if (sessionStorage.getItem("AuthToken")) {
    authToken = sessionStorage.getItem("AuthToken");
  }
  config.data.token = authToken;
  if (!config.data.request) {
    config.data.request = {};
  }
  return config;
};

/*Добавляем apiKey к запросу*/
const apiKeyInterceptor = (config) => {
  const apiKey = "30915f02-b0e8-4066-8540-1244d47d7304";
  config.headers.Authorization = `Bearer ${apiKey}`;

  return config;
};

/** добавлени логгера при каждом axios запросе */
const loggerInterceptor = (config) => {
  /** как-то обрабатываем логи */
  return config;
};

/** Добавляем экземпляру созданные обработчики для аутентификации и логов */
httpClient.interceptors.request.use(apiKeyInterceptor);
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);
/*logger*/
// httpClient.interceptors.request.use(AxiosLogger.requestLogger);
// httpClient.interceptors.response.use(AxiosLogger.responseLogger);
// httpClient.interceptors.request.use(
//   AxiosLogger.requestLogger,
//   AxiosLogger.errorLogger
// );
// httpClient.interceptors.response.use(
//   AxiosLogger.responseLogger,
//   AxiosLogger.errorLogger
// );

/** Добавление обработчика для результатов или ошибок при запросах */
httpClient.interceptors.response.use(
  (response) => {
    if (response.data.status !== 2000) {
      if (response.data.error.constructor.name === "Array") {
        response.data.error.forEach((error) => {
          setTimeout(() => {
            Notification.error({
              title: "Ошибка",
              message: error,
              offset: 85,
              duration: 6000,
            });
          }, 300);
        });
      } else if (response.data.error.constructor.name === "String") {
        Notification.error({
          title: "Ошибка",
          message: response.data.error,
          offset: 85,
          duration: 6000,
        });
      } else {
        Notification.error({
          title: "Ошибка",
          message: "Неизвестная ошибка",
          offset: 85,
          duration: 6000,
        });
      }
      /** Если пользователь не авторизован удаляем запись о токене и перенаправляем на страницу авторизации */
      if (response.data.status === 4009) {
        if (sessionStorage.getItem("AuthToken")) {
          sessionStorage.removeItem("AuthToken");
        }
        router.push({
          path: "/login",
          // Перейти на текущую страницу после успешного входа
          query: { redirect: router.currentRoute.fullPath },
        });
        return Promise.reject(response);
      }
      return Promise.reject(response);
    }

    if (response.data.status && response.data.status === 2000) {
      if (response.data.customerName) {
        store.commit("Main/customerName", response.data.customerName);
      }
      if (response.data.customerID) {
        store.commit("Main/customerID", response.data.customerID);
      }
      if (response.data.contactID) {
        store.commit("Main/contactID", response.data.contactID);
      }
      if (response.data.credit) {
        store.commit("Main/credit", response.data.credit);
      }
      if (response.data.admin) {
        store.commit("Main/IsAdmin", response.data.admin);
      }
      if (response.data.admin === false) {
        store.commit("Main/IsAdmin", false);
      }
      if (response.data.contactID) {
        store.commit("CostCalculation/contactID", response.data.contactID);
      }
    }
    return response;
  },

  (error) => {
    /** Как-то обрабатываем результат, завершенный ошибкой */
    console.log(error);
    Notification.error({
      title: "Ошибка",
      message: "Неизвестная ошибка",
      offset: 85,
      duration: 6000,
    });
    return Promise.reject(error);
  }
);

export { httpClient };
