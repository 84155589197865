/* Orders.store.js */
import { httpClient } from "@/services/http";
import store from "@/store/index.js";
import { Notification } from "element-ui";

import moment from "moment/dist/moment";
import "moment/dist/locale/ru";
moment.locale("ru");

const sklonenie = (number, txt, cases = [2, 0, 1, 1, 1, 2]) =>
  txt[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];

const initialState = () => ({
  getOrders: {},
  Order: {},
  listOrders: [],
  listOrdersPageCount: 1,
  listOrdersCurrentPage: 1,
  listOrdersTotal: 0,
  inWorkOrders: [],
  inWorkOrdersPageCount: 1,
  inWorkOrdersCurrentPage: 1,
  inWorkOrdersTotal: 0,
  getReceive: {},
  setReceive: {},
  Receive: [],
  setCompany: {},
  getCompanies: {},
  Companies: {},
  deleteOrder: {},
  checkCompany: {},
  customerOrderGetRatio: {},
  customerOrderSetRatio: {},
  customerOrderDescGet: {},
  customerOrderDescSet: {},
  customerFileListOrder: {},
  customerFileDeleteOrder: {},
  toWork: {},
  FileListOrder: [],
  OrderGetRatio: [],
  requestDataSetReceive: undefined,
  recipientData: {},
  OrderNote: "",
  BillGeneratePdfLink: "",
  KeyOrdersComponent: 5414454564878,
  cdekGetCity: {},
  cdekGetCityPoints: {},
  cdekPrice: undefined,
  cdekDays: "",
});

const state = initialState();

// Getter functions
const getters = {
  Order(state) {
    return state.Order;
  },
  listOrders(state) {
    return state.listOrders;
  },
  listOrdersPageCount(state) {
    return state.listOrdersPageCount;
  },
  listOrdersCurrentPage(state) {
    return state.listOrdersCurrentPage;
  },
  listOrdersTotal(state) {
    return state.listOrdersTotal;
  },
  inWorkOrders(state) {
    return state.inWorkOrders;
  },
  inWorkOrdersPageCount(state) {
    return state.inWorkOrdersPageCount;
  },
  inWorkOrdersCurrentPage(state) {
    return state.inWorkOrdersCurrentPage;
  },
  inWorkOrdersTotal(state) {
    return state.inWorkOrdersTotal;
  },
  Receive(state) {
    return state.Receive;
  },
  Companies(state) {
    return state.Companies;
  },
  OrderGetRatio(state) {
    return state.OrderGetRatio;
  },
  requestDataSetReceive(state) {
    return state.requestDataSetReceive;
  },
  recipientData(state) {
    return state.recipientData;
  },
  OrderNote(state) {
    return state.OrderNote;
  },
  FileListOrder(state) {
    return state.FileListOrder;
  },
  BillGeneratePdfLink(state) {
    return state.BillGeneratePdfLink;
  },
  KeyOrdersComponent(state) {
    return state.KeyOrdersComponent;
  },
  cdekPrice(state) {
    return state.cdekPrice;
  },
  cdekDays(state) {
    return state.cdekDays;
  },
};

// Actions
const actions = {
  Order({ commit }, oid) {
    return httpClient("/?action=getOrders", {
      data: {
        request: {
          type: "order",
          oid: oid,
        },
      },
    })
      .then((response) => {
        if (
          response.data &&
          response.data.status === 2000 &&
          response.data.response.orders
        ) {
          if (response.data.response.orders.length === 1) {
            let orders = response.data.response.orders.map((item) => {
              item.oDate = moment(item.oDate).format("DD MMMM YYYY, HH:mm");
              item.oName = item.oName.replace(/<\/?[^>]+(>|$)/g, " ");
              return item;
            });
            commit("Order", orders[0]);
          }
        }
        commit("getOrders", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  listOrders({ commit }, request) {
    return httpClient("/?action=customerOrderList", {
      data: {
        request: request,
      },
    })
      .then((response) => {
        if (response.data && response.data.status === 2000) {
          if (response.data.response.orders) {
            let orders = response.data.response.orders.map((item) => {
              item.oDate = moment(item.oDate).format("DD-MM-YYYY HH:mm");
              item.oName = item.oName.replace(/<\/?[^>]+(>|$)/g, " ");
              return item;
            });
            commit("listOrders", orders);
          }
          if (response.data.response.pages) {
            commit("listOrdersPageCount", response.data.response.pages);
          } else {
            commit("listOrdersPageCount", 1);
          }
          if (response.data.response.page) {
            commit("listOrdersCurrentPage", response.data.response.page);
          } else {
            commit("listOrdersCurrentPage", 1);
          }
          if (response.data.response.total) {
            commit("listOrdersTotal", response.data.response.total);
          }
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  inWorkOrders({ commit }, request) {
    return httpClient("/?action=customerOrderList", {
      data: {
        request: request,
      },
    })
      .then((response) => {
        if (response.data && response.data.status === 2000) {
          if (response.data.response.orders) {
            let orders = response.data.response.orders.map((item) => {
              item.oDate = moment(item.oDate).format("DD-MM-YYYY HH:mm");
              item.oReadyDate = moment(item.oReadyDate).format(
                "DD-MM-YYYY HH:mm"
              );
              item.oName = item.oName.replace(/<\/?[^>]+(>|$)/g, " ");
              return item;
            });
            commit("inWorkOrders", orders);
          }
          if (response.data.response.pages) {
            commit("inWorkOrdersPageCount", response.data.response.pages);
          } else {
            commit("inWorkOrdersPageCount", 1);
          }
          if (response.data.response.page) {
            commit("inWorkOrdersCurrentPage", response.data.response.page);
          } else {
            commit("inWorkOrdersCurrentPage", 1);
          }
          if (response.data.response.total) {
            commit("inWorkOrdersTotal", response.data.response.total);
          }
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  getReceive({ commit }, oid) {
    return httpClient("/?action=getReceive", {
      data: {
        request: { oid: oid },
      },
    })
      .then((response) => {
        if (
          response.data.status &&
          response.data.status === 2000 &&
          response.data.response.receive
        ) {
          commit("Receive", response.data.response.receive);
        }
        commit("getReceive", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  setReceive({ commit }) {
    return httpClient("/?action=setReceive", {
      data: {
        request: {
          ...state.requestDataSetReceive,
          ...state.recipientData,
        },
      },
    })
      .then((response) => {
        if (
          response.data.status &&
          response.data.status === 2000 &&
          response.data.response.receive
        ) {
          // commit("Receive", response.data.response.receive);
        }
        commit("setReceive", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  setCompany({ commit }, request) {
    return httpClient("/?action=setCompany", {
      data: {
        request: request,
      },
    })
      .then((response) => {
        commit("setCompany", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  getCompanies({ commit }) {
    return httpClient("/?action=getCompanies", {
      data: {},
    })
      .then((response) => {
        if (
          response.data.status &&
          response.data.status === 2000 &&
          response.data.response.customers
        ) {
          commit("Companies", response.data.response.customers);
        }
        commit("getCompanies", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  deleteOrder({ commit }, oid) {
    return httpClient("/?action=deleteOrder", {
      data: {
        request: { oid: oid },
      },
    })
      .then((response) => {
        commit("deleteOrder", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  checkCompany({ commit, dispatch }, request) {
    return httpClient("/?action=checkCompany", {
      data: {
        request: request.add,
      },
    })
      .then((response) => {
        commit("checkCompany", response.data);
        if (
          response &&
          response.data.status &&
          response.data.status === 2000 &&
          response.data.response.id &&
          request.oid !== false &&
          request.add.update
        ) {
          let req = {
            oid: request.oid,
            company: response.data.response.id,
          };
          dispatch("setCompany", req).then((response) => {
            if (response.data.status && response.data.status === 2000) {
              dispatch("getCompanies").then((response) => {
                if (response.data.status && response.data.status === 2000) {
                  dispatch("Order", request.oid);
                }
              });
              Notification({
                title: "Success",
                message: "Заказчик успешно изменен!",
                type: "success",
                offset: 85,
                duration: 6000,
              });
            }
          });
        }
        if (
          response &&
          response.data.status &&
          response.data.status === 2000 &&
          response.data.response.id &&
          request.oid === false &&
          request.add.update
        ) {
          store.dispatch("Settings/customerCompanyList");
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerOrderGetRatio({ commit }, oid) {
    return httpClient("/?action=customerOrderGetRatio", {
      data: {
        request: { oid: oid },
      },
    })
      .then((response) => {
        if (
          response &&
          response.data.status &&
          response.data.status === 2000 &&
          response.data.response.ratio
        ) {
          if (response.data.response.ratio.length > 0) {
            let ratio = response.data.response.ratio.map((item) => {
              item.date = moment(item.date).format("HH:mm DD/MM/YYYY");
              return item;
            });
            commit("OrderGetRatio", ratio);
          }
        }
        commit("customerOrderGetRatio", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerOrderSetRatio({ commit }, request) {
    return httpClient("/?action=customerOrderSetRatio", {
      data: {
        request: request,
      },
    })
      .then((response) => {
        commit("customerOrderSetRatio", response.data);
        if (
          response &&
          response.data.status &&
          response.data.status === 2000 &&
          response.data.response.ratio
        ) {
          // commit("OrderGetRatio", response.data.response.ratio);
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerOrderDescGet({ commit }, oid) {
    return httpClient("/?action=customerOrderDescGet", {
      data: {
        request: {
          oid: oid,
        },
      },
    })
      .then((response) => {
        commit("customerOrderDescGet", response.data);
        if (response.data.status && response.data.status === 2000) {
          commit("OrderNote", response.data.response.desc);
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerOrderDescSet({ commit }, request) {
    return httpClient("/?action=customerOrderDescSet", {
      data: {
        request: request,
      },
    })
      .then((response) => {
        commit("customerOrderDescSet", response.data);
        if (response.data.status && response.data.status === 2000) {
          commit("OrderNote", request.desc);
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerFileDeleteOrder({ commit }, request) {
    return httpClient("/?action=customerFileDeleteOrder", {
      data: {
        request: request,
      },
    })
      .then((response) => {
        commit("customerFileDeleteOrder", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerFileListOrder({ commit }, oid) {
    return httpClient("/?action=customerFileListOrder", {
      data: {
        request: {
          oid: oid,
        },
      },
    })
      .then((response) => {
        commit("customerFileListOrder", response.data);
        if (
          response.data.status &&
          response.data.status === 2000 &&
          response.data.response.files
        ) {
          commit("FileListOrder", response.data.response.files);
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  customerBillGeneratePDF({ commit }, oid) {
    return httpClient("/?action=customerBillGeneratePDF", {
      data: {
        request: {
          oid: oid,
        },
      },
    })
      .then((response) => {
        if (
          response.data.status &&
          response.data.status === 2000 &&
          response.data.response.link
        ) {
          commit("BillGeneratePdfLink", response.data.response.link);
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  toWork({ commit }, oid) {
    return httpClient("/?action=toWork", {
      data: {
        request: {
          oid: oid,
        },
      },
    })
      .then((response) => {
        commit("toWork", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  cdekGetCity({ commit }, search) {
    return httpClient("/?action=cdekGetCity", {
      data: {
        request: {
          search: search,
        },
      },
    })
      .then((response) => {
        commit("cdekGetCity", response.data);
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  cdekGetCityPoints({ commit }, request) {
    return httpClient("/?action=cdekGetCityPoints", {
      data: {
        request: request,
      },
    })
      .then((response) => {
        commit("cdekGetCityPoints", response.data);
        if (response.data.status === 2000 && response.data.response.summ) {
          commit("cdekPrice", response.data.response.summ);
          if (
            response.data.response.daysMin &&
            response.data.response.daysMax
          ) {
            let textDays = "";
            if (
              response.data.response.daysMin === response.data.response.daysMax
            ) {
              textDays = `${response.data.response.daysMax} ${sklonenie(
                response.data.response.daysMax,
                ["день", "дня", "дней"]
              )}`;
            } else {
              textDays = `${response.data.response.daysMin} - ${
                response.data.response.daysMax
              } ${sklonenie(response.data.response.daysMax, [
                "день",
                "дня",
                "дней",
              ])}`;
            }

            commit("cdekDays", textDays);
          }
        } else {
          commit("cdekPrice", undefined);
          commit("cdekDays", "");
        }
        return response;
      })
      .catch((error) => {
        commit("cdekPrice", undefined);
        commit("cdekDays", "");
        return error;
      });
  },
};
// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  getOrders(state, value) {
    state.getOrders = value;
  },
  Order(state, value) {
    state.Order = value;
  },
  listOrders(state, value) {
    state.listOrders = value;
  },
  listOrdersPageCount(state, value) {
    state.listOrdersPageCount = value;
  },
  listOrdersCurrentPage(state, value) {
    state.listOrdersCurrentPage = value;
  },
  listOrdersTotal(state, value) {
    state.listOrdersTotal = value;
  },
  inWorkOrders(state, value) {
    state.inWorkOrders = value;
  },
  inWorkOrdersPageCount(state, value) {
    state.inWorkOrdersPageCount = value;
  },
  inWorkOrdersCurrentPage(state, value) {
    state.inWorkOrdersCurrentPage = value;
  },
  inWorkOrdersTotal(state, value) {
    state.inWorkOrdersTotal = value;
  },
  getReceive(state, value) {
    state.getReceive = value;
  },
  setReceive(state, value) {
    state.setReceive = value;
  },
  Receive(state, value) {
    state.Receive = value;
  },
  setCompany(state, value) {
    state.setCompany = value;
  },
  getCompanies(state, value) {
    state.getCompanies = value;
  },
  Companies(state, value) {
    state.Companies = value;
  },
  deleteOrder(state, value) {
    state.deleteOrder = value;
  },
  checkCompany(state, value) {
    state.checkCompany = value;
  },
  customerOrderGetRatio(state, value) {
    state.customerOrderGetRatio = value;
  },
  customerOrderSetRatio(state, value) {
    state.customerOrderSetRatio = value;
  },
  OrderGetRatio(state, value) {
    state.OrderGetRatio = value;
  },
  requestDataSetReceive(state, value) {
    state.requestDataSetReceive = value;
  },
  recipientData(state, value) {
    state.recipientData = value;
  },
  customerOrderDescGet(state, value) {
    state.customerOrderDescGet = value;
  },
  customerOrderDescSet(state, value) {
    state.customerOrderDescSet = value;
  },
  OrderNote(state, value) {
    state.OrderNote = value;
  },
  customerFileDeleteOrder(state, value) {
    state.customerFileDeleteOrder = value;
  },
  customerFileListOrder(state, value) {
    state.customerFileListOrder = value;
  },
  FileListOrder(state, value) {
    state.FileListOrder = value;
  },
  BillGeneratePdfLink(state, value) {
    state.BillGeneratePdfLink = value;
  },
  toWork(state, value) {
    state.toWork = value;
  },
  KeyOrdersComponent(state, value) {
    state.KeyOrdersComponent = value;
  },
  cdekGetCity(state, value) {
    state.cdekGetCity = value;
  },
  cdekGetCityPoints(state, value) {
    state.cdekGetCityPoints = value;
  },
  cdekPrice(state, value) {
    state.cdekPrice = value;
  },
  cdekDays(state, value) {
    state.cdekDays = value;
  },
  // other mutations
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
