/* Cc.store.js */
import { httpClient } from "@/services/http";

const initialState = () => ({
  getTicket: {},
  setLinks: {},
  TicketResponse: {},
  Tickets: [],
  Hires: [],
  Snapshot: null,
});

const state = initialState();

// Getter functions
const getters = {
  TicketResponse(state) {
    return state.TicketResponse;
  },
  Tickets(state) {
    return state.Tickets;
  },
  Hires(state) {
    return state.Hires;
  },
  Snapshot(state) {
    return state.Snapshot;
  },
};

// Actions
const actions = {
  getTicket({ commit }, oid) {
    return httpClient("/?action=getTicket", {
      data: {
        request: {
          oid: oid,
        },
      },
    })
      .then((response) => {
        if (response.data.status && response.data.status === 2000) {
          if (response.data.response.tickets) {
            let TICKET = response.data.response.tickets;
            if (TICKET.length > 1) {
              TICKET.sort((prev, next) => prev.order - next.order);
            }
            commit("Tickets", TICKET);
          }
          if (response.data.response) {
            commit("TicketResponse", response.data.response);
          }
          if (response.data.response.hires) {
            commit("Hires", response.data.response.hires);
          }
          if (response.data.response.snapshot) {
            commit("Snapshot", response.data.response.snapshot);
          }
          commit("getTicket", response.data);
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  setLinks({ commit }, data) {
    return httpClient("/?action=setLinks", {
      data: {
        request: data,
      },
    })
      .then((response) => {
        commit("setLinks", response.data);
        if (response.data.status && response.data.status === 2000) {
          if (response.data.response) {
            // commit("TicketResponse", response.data.response);
          }
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },

  deleteLinks({ commit }, oid) {
    return httpClient("/?action=deleteLinks", {
      data: {
        request: {
          oid: oid,
        },
      },
    })
      .then((response) => {
        commit("deleteLinks", response.data);
        if (response.data.status && response.data.status === 2000) {
          if (response.data.response) {
            // commit("TicketResponse", response.data.response);
          }
        }
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
};
// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  getTicket(state, value) {
    state.getTicket = value;
  },
  setLinks(state, value) {
    state.setLinks = value;
  },
  TicketResponse(state, value) {
    state.TicketResponse = value;
  },
  Tickets(state, value) {
    state.Tickets = value;
  },
  Hires(state, value) {
    state.Hires = value;
  },
  Snapshot(state, value) {
    state.Snapshot = value;
  },
  // other mutations
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
